/** @jsx jsx */
import { jsx } from "@emotion/core"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import { Container } from "react-bootstrap"

const LegalNoticePage = () => {
  return (
    <Layout footerHalf={false}>
      <SEO title="Mentions légales" />

      <Section className="py-5" css={{ marginTop: "60px" }}>
        <Container className="content">
          <h1 className="mb-0">Mentions légales</h1>
        </Container>
        <Section className="pt-0 content">
          <Container className="pt-4">
            <h2>Éditeur</h2>
            <p>Le site Simulassur.fr est la propriété de la société ELOIS.</p>
            <p>ELOIS</p>
            <p>SAS au capital de 100 000€</p>
            <p>Siège social : 23 rue du Départ – 75014 Paris</p>
            <p>
              Courtier en assurance immatriculé à l’ORIAS (
              <a href="http://www.orias.fr">www.orias.fr</a>) sous le n°07 019
              295
            </p>
            <p>Inscrite au RCS de Paris sous le numéro 483 413 258</p>
            <p>
              ELOIS est titulaire d’une assurance Responsabilité Civile
              Professionnelle et d’une Garantie Financière conformes au Code des
              Assurances.
            </p>
            <p>
              L’autorité chargée du contrôle de ELOIS est l’Autorité de Contrôle
              Prudentiel et de Résolution (ACPR) située 4 Place de
              Budapest CS 92459, 75436 Paris Cedex 09.
            </p>
            <h2>Hébergement</h2>
            <p>AMAZON DATA SERVICES FRANCE SAS</p>
            <p>SASU au capital de 182 001 000 €</p>
            <p>RCS Nanterre B 824 031 090</p>
            <p>Code APE 6203Z</p>
            <p>N° TVA : FR73824031090</p>
            <p>
              Siège social : 31 PLACE DES COROLLES - 92400 COURBEVOIE - France
            </p>
          </Container>
        </Section>
      </Section>
    </Layout>
  )
}

export default LegalNoticePage
